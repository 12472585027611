require('normalize.css/normalize.css');
require('./styles/index.scss');

import * as two from 'two.js';
import * as url from 'url.js';
import * as has from './plugins/has';

function init() {

    //Stage is set (without the type, probably svg atm – should be canvas in original script)
    const two = new Two({
        type: Two.Types['canvas'],
        fullscreen: true,
        autostart: true,
    }).appendTo(document.body);

    const typedCharacters = [];
    let theGravity = new Two.Vector(0, 0.77);
    
    const styles = {
        family: 'Arial, sans-serif',
        size: 33,
        leading: 50,
        weight: 900
    };

    window.addEventListener('keydown', (e) => {
        let character = e.key;
                //call add function with key as string
        if (e.key !== 'Shift') {
        add(character) } else return;
    })
    
    window.addEventListener('touchstart', (e) => {
        let r = Math.floor(Math.random() * 15);
        let letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U', ]
        // For mobile make it return random charactor on touch instead
        let character = letters[r];
        //call add function with key as string
        add(character);
        playSound(character, true);
    })
    
    // When scene is updated
     two.bind('update', function() {
        for (let i = 0; i < typedCharacters.length; i++) {

            let text = typedCharacters[i];
            text.translation.addSelf(text.velocity);
            text.rotation += text.velocity.r;
            text.velocity.addSelf(theGravity);

            if (text.velocity.y > 0 && text.translation.y > two.height) {
                two.scene.remove(text);
                typedCharacters.splice(i, 1);
            }
        }
     });

    function add(message) {
        let x = Math.random() * two.width / 2 + two.width / 4;
        let y = two.height * 1.25;

        let text = two.makeText(message, x, y, styles);
        text.size *= 3;
        text.fill = '#fff';

        text.velocity = new Two.Vector();
        text.velocity.x = 10 * (Math.random() - .6);
        text.velocity.y = - (24 * Math.random() + 13);
        text.velocity.r = Math.random() * Math.PI / 8;
        
        typedCharacters.push(text);
    }


    function playSound(e, mobile) {
        let audio = document.querySelector(`audio[data-key="${e.keyCode}"]`);
        let audioBlurb = document.querySelectorAll(`audio`);
        let audioArr = Array.from(audioBlurb)
        let r = Math.floor(Math.random() * 15);

        if (!audio && mobile) {
            audio = audioArr[r]
        } else if (!audio) {
            audio = audioArr[1]
        };

        audio.currentTime = 0;
        audio.play();
    };
    
    
    window.addEventListener('keydown', playSound);
}
  
window.addEventListener("DOMContentLoaded", () => {
 init();
})

